<template>
  <div class="footer">
    <van-collapse v-model="activeNames" :border="false">
      <van-collapse-item title="走进数壤" name="1">
        <a href="/h5/enterdigiland">数壤介绍</a><br />
        <a href="/h5/enterdigiland#growup">发展历程</a><br />
        <a href="/h5/enterdigiland#soil">数字土壤</a><br />
        <a href="/h5/enterdigiland#join">加入数壤</a><br />
        <a href="/h5/enterdigiland">联系我们</a><br />
      </van-collapse-item>
      <van-collapse-item title="产品导航" name="2">
        <a href="/h5/productoverview#manage">数字厂长（管理型）</a><br />
        <a href="/h5/productoverview#market">数字厂长（营销型）</a><br />
        <a href="/h5/productoverview#maps">产业地图</a><br />
        <a href="/h5/productoverview#twin">数字孪生</a><br />
        <a href="/h5/productoverview">数壤元宇宙</a><br />
      </van-collapse-item>
      <van-collapse-item title="客户案例" name="3">
        <a href="/customerstories">企业案例</a><br />
        <a href="/customerstories">政府案例</a><br />
        <a href="/customerstories">媒体报道</a><br />
        <a href="/customerstories">产品更新</a><br />
      </van-collapse-item>
      <van-collapse-item title="数壤课堂" name="4">
        <a href="/class#expert">专家简介</a><br />
        <a href="/class#classes">数壤小课堂</a><br />
        <a href="/class#courses">数壤精品课</a><br />
        <a href="/class#institute">数壤研究院</a><br />
      </van-collapse-item>
    </van-collapse>
    <div class="footer_picInfo">
      <div class="footer_pic">
        <img :src="require('@/assets/image/gongzhonghao.png')" />
        <div class="footer_info">公众号</div>
      </div>
      <div class="footer_pic">
        <img :src="require('@/assets/image/douyinghao.png')" />
        <div class="footer_info">抖音号</div>
      </div>
    </div>
    <div class="icp">沪ICP备2021021769号-3</div>
  </div>
</template>
<script>
import { Collapse, CollapseItem } from 'vant';
export default {
  components: {
    vanCollapse: Collapse,
    vanCollapseItem: CollapseItem,
  },
  data() {
    return {
      activeNames: ['1', '2', '3', '4',],
    };
  },
}
</script>
<style lang="less" scoped>
a {
  color: white;
}

.footer {
  background: #001810;
}

/deep/.van-cell {
  font-size: 12px;
  color: #FFFFFF;
  background: #001810;
  padding: 10px 16px 0;
}

/deep/.van-icon {
  color: #FFFFFF;
}

/deep/.van-collapse-item__content {
  font-size: 10px;
  line-height: 24px;
  color: #FFFFFF;
  background: #001810;
}

/deep/.van-collapse-item__title--expanded::after {
  display: none;
}

.footer_picInfo {
  margin: 15px 15px 0;
  padding: 15px 0;
  border-top: 1px solid rgba(255, 255, 255, 0.65);
  display: flex;

  .footer_pic {
    margin-right: 20px;
    text-align: center;

    .footer_info {
      margin-top: 10px;
      font-size: 10px;
      font-weight: 400;
      color: rgba(255, 255, 255, 0.85);
      line-height: 14px;
    }
  }
}
.icp{
  padding-bottom: 15px;
  width: 100%;
  text-align: center;
  color: #fff;
  z-index: 9;
  font-size: 12px;
}
</style>
