<template>
  <div class="hender">
    <img @click="showMenu" class="more" :src="require('@/assets/image/h5/home/more.png')" alt="" />
    <img class="logo" :src="require('@/assets/image/h5/home/logo.png')" alt="" />
    <div class="navbar-collapse">
      <ul class="nav navbar-nav navbar-right" :style="{height:menuHeight+'rem'}">
        <li
            v-for="(item, index) of menuList"
            class="eADydo " :class="{ kHWaLx: item.active }"
            :key="index"
          >
            <a :href="item.link">{{ item.name }}</a>
          </li>
      </ul>
    </div>
  </div>
</template>
<script>
export default {
  components: {
  },
  data() {
    return {
      menuList: [
        {
          name: "首页",
          link: "/h5/home",
          routeName: "Mhome",
          active: false,
        },
        {
          name: "产品概览",
          link: "/h5/productoverview",
          routeName: "Mproductoverview",
          active: false,
        },
        {
          name: "客户案例",
          link: "/h5/customerstories",
          routeName: "Mcustomerstories",
          active: false,
        },
        {
          name: "走进数壤",
          link: "/h5/enterdigiland",
          routeName: "MenterDigiland",
          active: false,
        },
        {
          name: "数壤课堂",
          link: "/h5/class",
          routeName: "Mclass",
          active: false,
        },
        {
          name: "数壤合伙人",
          link: "/h5/partner",
          routeName: "Mpartner",
          active: false,
        },
        {
          name: "数壤代理商",
          link: "/h5/agent",
          routeName: "Magent",
          active: false,
        },
      ],
      menuHeight:0
    };
  },
  created() {
    this.menuList.map((x) => {
      x.active = false;
      if (x.routeName == this.$route.name) {
        x.active = true;
      }
    });
  },
  methods: {
    showMenu() {
      let times = null;
      if (this.menuHeight == 0) {
        times = setInterval(() => {
          this.menuHeight += 1.3333; 
          if (this.menuHeight > 8) {
            this.menuHeight = 9.3331;
            clearInterval(times)
          }
        },40)
      } else {
        times = setInterval(() => {
          this.menuHeight -= 1.3333; 
          if (this.menuHeight < 0) {
            this.menuHeight = 0;
            clearInterval(times)
          }
        },40)
      }
    }
  }
}
</script>
<style lang="less" scoped>
.hender {
  position: sticky;
  top: 0;
  height: 44px;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  z-index: 99;

  .more {
    position: absolute;
    top: 12px;
    left: 12px;
    width: 20px;
    height: 20px;
  }

  .logo {
    width: 76px;
    height: 30px;
  }
}

.navbar-collapse {
  position: absolute;
  top: 44px;
  width: 100%;
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, .1);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, .1);
  border-top: 1px solid rgb(231, 231, 231) !important;
  background-color: white !important;
  box-sizing: border-box;

  .eADydo {
    height: 50px;
    line-height: 50px;
    font-size: 15px;
    padding: 0px 15px;
    background-color: white;

    a {
      display: block;
      color: rgba(0, 0, 0, 0.65);
    }

    &.kHWaLx {
      background: #308971;

      a {
        color: #fff;
      }
    }
  }
}
</style>
